<template>
	<NuxtLink
		v-if="href"
		:to="href"
		:class="mergedClass"
		:target="openNewWindow ? `_blank` : '_self'"
		v-bind="attrs"
		:disabled="isDisabled || isLoading"
	>
		<div v-if="isLoading" class="w-full flex items-center justify-center gap-2.5">
			<i class="fi fi-sr-spinner animate-spin"></i> Loading
		</div>
		<slot v-else></slot>
	</NuxtLink>
	<button v-else :class="mergedClass" v-bind="attrs" :disabled="isDisabled || isLoading">
		<div v-if="isLoading" class="w-full flex items-center justify-center gap-2.5">
			<i class="fi fi-sr-spinner animate-spin"></i> Loading
		</div>
		<slot v-else></slot>
	</button>
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

const props = defineProps<{
	variant?: 'primary' | 'secondary' | 'success' | 'transparent'
	href?: string
	isLoading?: boolean
	isDisabled?: boolean
	loadingText?: string
}>()

const attrs = useAttrs()

defineOptions({
	inheritAttrs: false,
})

const mergedClass = computed(() =>
	twMerge(
		'px-4 py-2 rounded-lg text-center flex items-center justify-center text-sm font-medium transition-all duration-200 ease-in-out',
		{
			primary: 'bg-white flex items-center justify-center text-sm text-black w-full p-4 gap-2.5',
			secondary: 'bg-[#5B5B5B33] flex items-center justify-center w-fit p-4 gap-2.5',
			success: 'bg-brand-primary text-black w-full p-4',
			transparent: 'bg-transparent text-white w-full',
		}[props.variant || 'primary'],
		props.isLoading || props.isDisabled ? 'opacity-50 cursor-not-allowed' : '',
		attrs.class as string
	)
)

const openNewWindow = computed(() => (props.href as string)?.startsWith('https://'))
</script>

<style scoped>
.loader {
	width: 1rem;
	height: 1rem;
	border: 2px solid transparent;
	border-top: 2px solid currentColor;
	border-radius: 50%;
	animation: spin 0.6s linear infinite;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
</style>
